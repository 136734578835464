<template>
  <div class="flex text-text_alt_color">
    <div class="nav-section">
      <slot name="right" />
    </div>

    <div v-if="showCart" v class="flex text-nav_color w-[4.8rem] relative">
      <div
        class="absolute top-0 bottom-0 -left-0.5 -right-0.5"
        v-html="svg"
      ></div>
      <Transition name="bounce">
        <div :key="cart.items.length">
          <div
            class="absolute bottom-1/3 left-1/2 -translate-x-1/2 translate-y-1 w-12 h-12 bg-button_color rounded-full border border-nav_color/50 shadow-nav"
          ></div>

          <SelaLink to="/cart" :with-branch-id-query="useIsDomain()">
            <div
              class="absolute bottom-1/3 left-1/2 -translate-x-1/2 translate-y-1 -ml-0.5"
            >
              <v-icon :size="40" class="text-text_alt_color">
                <IconsCartBottomNavigation />
              </v-icon>
            </div>

            <div
              class="absolute bottom-1/3 left-1/2 -translate-x-1/2 text-button_color mb-1 font-medium"
            >
              {{ cart.badgeCount }}
            </div>
          </SelaLink>
        </div>
      </Transition>
    </div>

    <div class="nav-section">
      <slot name="left" />
    </div>
  </div>
</template>

<script setup lang="ts">
const svg =
  '<svg viewBox="0 0 85.429832 43.818138" version="1.1" id="svg5" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg" fill="currentColor"><defs id="defs2" /><g id="layer1" transform="translate(1.9405835,1.5511107)"><g id="g438"><path style="stroke-width:0.264583" d="M -1.9405835,42.267024 V -1.5443888 c 0,0 11.9626805,1.54483680431 11.9503415,13.0624018 0.06764,21.817781 21.610189,26.699119 31.628504,26.699119 0,3.46417 0.05151,3.868839 0.05151,3.868839 z" id="path162" /><path style="stroke-width:0.264583" d="M 83.489251,42.260302 V -1.5511108 c 0,0 -11.962681,1.54483695 -11.950342,13.0624018 -0.06764,21.817781 -21.610189,26.699119 -31.628504,26.699119 0,3.46417 -0.05151,3.868839 -0.05151,3.868839 z" id="path162-7" /></g></g></svg>'

const cart = useCartStore()
withDefaults(
  defineProps<{
    showCart?: boolean
  }>(),
  {
    showCart: true
  }
)
</script>

<style>
.nav-section {
  @apply flex-1 bg-nav_color flex items-center justify-evenly;
}

.nav-section .link {
  @apply p-2 transition-all duration-500;
}

.nav-section .link.router-link-exact-active {
  @apply bg-text_alt_color text-nav_color;
}

.bounce-enter-active {
  animation: bounce-in 1s;
}

@keyframes bounce-in {
  0% {
    transform: scale(1);
    translate: -39px 0;
  }
  25% {
    transform: scale(0.9);
    translate: -39px 0;
  }
  75% {
    transform: scale(1.3);
    translate: -39px 0;
  }
  100% {
    transform: scale(1);
    translate: -39px 0;
  }
}
</style>
